@charset "UTF-8";
/*
Column size

One column – 308
Two column – 636
Three column – 964
Mobile – 312 minimum (does this scale on larger phones?)
*/
.video-js .hidden {
  display: none; }

.clearfix:after {
  content: " ";
  /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

/* Custom Icon Font
--------------------------------------------------------------------------------
The control icons are from a custom font. Each icon corresponds to a character
(e.g. "\e001"). Font icons allow for easy scaling and coloring of icons.
*/
@font-face {
  font-family: 'MolVideo';
  src: url("font/mol-video.eot"); }

@font-face {
  font-family: 'MolVideo';
  src: url("font/mol-video.eot");
  src: url("font/mol-video.eot?#iefix") format("embedded-opentype"), url("font/mol-video.woff") format("woff"), url("font/mol-video.ttf") format("truetype"), url("font/mol-video.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

.video-js {
  position: relative;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-family: Arial, sans-serif;
  color: #B1B1B1;
  vertical-align: middle;
  /* user-select */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* transition */
  -webkit-transition: opacity 200ms ease-in-out;
  -moz-transition: opacity 200ms ease-in-out;
  -o-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out; }

div.video-js.vjs-visually-ready {
  opacity: 1; }

.vjs-tech {
  display: block;
  width: 100%; }

.vjs-hidden {
  display: none !important; }

.vjs-shown {
  display: block !important; }

.vjs-video-container,
#mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container {
  background-color: #000;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .vjs-video-container.vjs-fixed.vjs-span-one-col,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-fixed.vjs-span-one-col {
    width: 308px !important; }
  .vjs-video-container.vjs-fixed.vjs-span-two-col,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-fixed.vjs-span-two-col {
    width: 636px !important; }
    .vjs-video-container.vjs-fixed.vjs-span-two-col .vjs-newsletter-wrapper,
    .vjs-video-container.vjs-fixed.vjs-span-two-col .vjs-mailmsg-wrapper,
    #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-fixed.vjs-span-two-col .vjs-newsletter-wrapper,
    #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-fixed.vjs-span-two-col .vjs-mailmsg-wrapper {
      display: none; }
  .vjs-video-container.vjs-fixed.vjs-span-three-col,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-fixed.vjs-span-three-col {
    width: 964px !important; }
  .vjs-video-container::before,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    padding-bottom: 86px; }
  .vjs-video-container > *,
  .vjs-video-container .VPAID-container > *,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container > *,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container .VPAID-container > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important; }
  .vjs-video-container video.vjs-tech,
  .vjs-video-container object.vjs-tech,
  .vjs-video-container embed,
  .vjs-video-container .vjs-poster,
  .vjs-video-container .VPAID-container,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container video.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container object.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container embed,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container .vjs-poster,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container .VPAID-container {
    top: 36px;
    bottom: 50px;
    height: calc(100% - 86px);
    left: 0;
    right: 0;
    position: absolute; }
  .vjs-video-container.vjs-responsive::before, .vjs-video-container.vjs-one-col-title::before,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive::before,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title::before {
    padding-bottom: 84px; }
  .vjs-video-container.vjs-responsive video.vjs-tech,
  .vjs-video-container.vjs-responsive object.vjs-tech,
  .vjs-video-container.vjs-responsive embed,
  .vjs-video-container.vjs-responsive .vjs-poster,
  .vjs-video-container.vjs-responsive .VPAID-container, .vjs-video-container.vjs-one-col-title video.vjs-tech,
  .vjs-video-container.vjs-one-col-title object.vjs-tech,
  .vjs-video-container.vjs-one-col-title embed,
  .vjs-video-container.vjs-one-col-title .vjs-poster,
  .vjs-video-container.vjs-one-col-title .VPAID-container,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive video.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive object.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive embed,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive .vjs-poster,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive .VPAID-container,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title video.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title object.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title embed,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title .vjs-poster,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title .VPAID-container {
    top: 44px;
    bottom: 40px;
    height: calc(100% - 84px); }
  .vjs-video-container.vjs-responsive .vjs-fullscreen video.vjs-tech,
  .vjs-video-container.vjs-responsive .vjs-fullscreen object.vjs-tech,
  .vjs-video-container.vjs-responsive .vjs-fullscreen embed,
  .vjs-video-container.vjs-responsive .vjs-fullscreen .vjs-poster,
  .vjs-video-container.vjs-responsive .vjs-fullscreen .VPAID-container, .vjs-video-container.vjs-one-col-title .vjs-fullscreen video.vjs-tech,
  .vjs-video-container.vjs-one-col-title .vjs-fullscreen object.vjs-tech,
  .vjs-video-container.vjs-one-col-title .vjs-fullscreen embed,
  .vjs-video-container.vjs-one-col-title .vjs-fullscreen .vjs-poster,
  .vjs-video-container.vjs-one-col-title .vjs-fullscreen .VPAID-container,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive .vjs-fullscreen video.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive .vjs-fullscreen object.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive .vjs-fullscreen embed,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive .vjs-fullscreen .vjs-poster,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-responsive .vjs-fullscreen .VPAID-container,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title .vjs-fullscreen video.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title .vjs-fullscreen object.vjs-tech,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title .vjs-fullscreen embed,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title .vjs-fullscreen .vjs-poster,
  #mobileGalleryModal #mobileGallerySlider .slide-container .vjs-video-container.vjs-one-col-title .vjs-fullscreen .VPAID-container {
    height: calc(100% - 94px);
    bottom: 50px; }

/* Custom Icon Font
--------------------------------------------------------------------------------
*/
.coffeebreak .vjs-big-play-button {
  background-color: #823d1e;
  background-color: rgba(130, 61, 30, 0.8); }

.coffeebreak .vjs-play-progress,
.coffeebreak .vjs-play-control.vjs-paused,
.coffeebreak .vjs-volume-level {
  background: #823d1e no-repeat; }

.coffeebreak .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #823d1e;
  background-color: #823d1e; }

.coffeebreak .streaming .vjs-poster {
  background-color: #823d1e;
  background-color: rgba(130, 61, 30, 0.8); }

.dailymailtv .vjs-big-play-button {
  background-color: #004db3;
  background-color: rgba(0, 77, 179, 0.8); }

.dailymailtv .vjs-play-progress,
.dailymailtv .vjs-play-control.vjs-paused,
.dailymailtv .vjs-volume-level {
  background: #004db3 no-repeat; }

.dailymailtv .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #004db3;
  background-color: #004db3; }

.dailymailtv .streaming .vjs-poster {
  background-color: #004db3;
  background-color: rgba(0, 77, 179, 0.8); }

.debate .vjs-big-play-button {
  background-color: #a31414;
  background-color: rgba(163, 20, 20, 0.8); }

.debate .vjs-play-progress,
.debate .vjs-play-control.vjs-paused,
.debate .vjs-volume-level {
  background: #a31414 no-repeat; }

.debate .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #a31414;
  background-color: #a31414; }

.debate .streaming .vjs-poster {
  background-color: #a31414;
  background-color: rgba(163, 20, 20, 0.8); }

.event .vjs-big-play-button {
  background-color: #00b1a6;
  background-color: rgba(0, 177, 166, 0.8); }

.event .vjs-play-progress,
.event .vjs-play-control.vjs-paused,
.event .vjs-volume-level {
  background: #00b1a6 no-repeat; }

.event .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #00b1a6;
  background-color: #00b1a6; }

.event .streaming .vjs-poster {
  background-color: #00b1a6;
  background-color: rgba(0, 177, 166, 0.8); }

.femail .vjs-big-play-button {
  background-color: #c562a5;
  background-color: rgba(197, 98, 165, 0.8); }

.femail .vjs-play-progress,
.femail .vjs-play-control.vjs-paused,
.femail .vjs-volume-level {
  background: #c562a5 no-repeat; }

.femail .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #c562a5;
  background-color: #c562a5; }

.femail .streaming .vjs-poster {
  background-color: #c562a5;
  background-color: rgba(197, 98, 165, 0.8); }

.health .vjs-big-play-button {
  background-color: #3cc;
  background-color: rgba(51, 204, 204, 0.8); }

.health .vjs-play-progress,
.health .vjs-play-control.vjs-paused,
.health .vjs-volume-level {
  background: #3cc no-repeat; }

.health .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #3cc;
  background-color: #3cc; }

.health .streaming .vjs-poster {
  background-color: #3cc;
  background-color: rgba(51, 204, 204, 0.8); }

.horoscopes .vjs-big-play-button {
  background-color: #083064;
  background-color: rgba(8, 48, 100, 0.8); }

.horoscopes .vjs-play-progress,
.horoscopes .vjs-play-control.vjs-paused,
.horoscopes .vjs-volume-level {
  background: #083064 no-repeat; }

.horoscopes .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #083064;
  background-color: #083064; }

.horoscopes .streaming .vjs-poster {
  background-color: #083064;
  background-color: rgba(8, 48, 100, 0.8); }

.money .vjs-big-play-button {
  background-color: #451344;
  background-color: rgba(69, 19, 68, 0.8); }

.money .vjs-play-progress,
.money .vjs-play-control.vjs-paused,
.money .vjs-volume-level {
  background: #451344 no-repeat; }

.money .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #451344;
  background-color: #451344; }

.money .streaming .vjs-poster {
  background-color: #451344;
  background-color: rgba(69, 19, 68, 0.8); }

.moslive .vjs-big-play-button {
  background-color: #00b1a6;
  background-color: rgba(0, 177, 166, 0.8); }

.moslive .vjs-play-progress,
.moslive .vjs-play-control.vjs-paused,
.moslive .vjs-volume-level {
  background: #00b1a6 no-repeat; }

.moslive .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #00b1a6;
  background-color: #00b1a6; }

.moslive .streaming .vjs-poster {
  background-color: #00b1a6;
  background-color: rgba(0, 177, 166, 0.8); }

.motoring .vjs-big-play-button {
  background-color: #666;
  background-color: rgba(102, 102, 102, 0.8); }

.motoring .vjs-play-progress,
.motoring .vjs-play-control.vjs-paused,
.motoring .vjs-volume-level {
  background: #666 no-repeat; }

.motoring .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #666;
  background-color: #666; }

.motoring .streaming .vjs-poster {
  background-color: #666;
  background-color: rgba(102, 102, 102, 0.8); }

.news .vjs-big-play-button {
  background-color: #00aad2;
  background-color: rgba(0, 170, 210, 0.8); }

.news .vjs-play-progress,
.news .vjs-play-control.vjs-paused,
.news .vjs-volume-level {
  background: #00aad2 no-repeat; }

.news .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #00aad2;
  background-color: #00aad2; }

.news .streaming .vjs-poster {
  background-color: #00aad2;
  background-color: rgba(0, 170, 210, 0.8); }

.nochannel .vjs-big-play-button {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8); }

.nochannel .vjs-play-progress,
.nochannel .vjs-play-control.vjs-paused,
.nochannel .vjs-volume-level {
  background: #000 no-repeat; }

.nochannel .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #000;
  background-color: #000; }

.nochannel .streaming .vjs-poster {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8); }

.pictures .vjs-big-play-button {
  background-color: #f00;
  background-color: rgba(255, 0, 0, 0.8); }

.pictures .vjs-play-progress,
.pictures .vjs-play-control.vjs-paused,
.pictures .vjs-volume-level {
  background: #f00 no-repeat; }

.pictures .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #f00;
  background-color: #f00; }

.pictures .streaming .vjs-poster {
  background-color: #f00;
  background-color: rgba(255, 0, 0, 0.8); }

.property .vjs-big-play-button {
  background-color: #ac46c6;
  background-color: rgba(172, 70, 198, 0.8); }

.property .vjs-play-progress,
.property .vjs-play-control.vjs-paused,
.property .vjs-volume-level {
  background: #ac46c6 no-repeat; }

.property .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #ac46c6;
  background-color: #ac46c6; }

.property .streaming .vjs-poster {
  background-color: #ac46c6;
  background-color: rgba(172, 70, 198, 0.8); }

.royal_wedding .vjs-big-play-button {
  background-color: #540c75;
  background-color: rgba(84, 12, 117, 0.8); }

.royal_wedding .vjs-play-progress,
.royal_wedding .vjs-play-control.vjs-paused,
.royal_wedding .vjs-volume-level {
  background: #540c75 no-repeat; }

.royal_wedding .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #540c75;
  background-color: #540c75; }

.royal_wedding .streaming .vjs-poster {
  background-color: #540c75;
  background-color: rgba(84, 12, 117, 0.8); }

.sciencetech .vjs-big-play-button {
  background-color: #f60;
  background-color: rgba(255, 102, 0, 0.8); }

.sciencetech .vjs-play-progress,
.sciencetech .vjs-play-control.vjs-paused,
.sciencetech .vjs-volume-level {
  background: #f60 no-repeat; }

.sciencetech .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #f60;
  background-color: #f60; }

.sciencetech .streaming .vjs-poster {
  background-color: #f60;
  background-color: rgba(255, 102, 0, 0.8); }

.sport .vjs-big-play-button {
  background-color: #0cac0c;
  background-color: rgba(12, 172, 12, 0.8); }

.sport .vjs-play-progress,
.sport .vjs-play-control.vjs-paused,
.sport .vjs-volume-level {
  background: #0cac0c no-repeat; }

.sport .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #0cac0c;
  background-color: #0cac0c; }

.sport .streaming .vjs-poster {
  background-color: #0cac0c;
  background-color: rgba(12, 172, 12, 0.8); }

.strayer .vjs-big-play-button {
  background-color: #b92025;
  background-color: rgba(185, 32, 37, 0.8); }

.strayer .vjs-play-progress,
.strayer .vjs-play-control.vjs-paused,
.strayer .vjs-volume-level {
  background: #b92025 no-repeat; }

.strayer .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #b92025;
  background-color: #b92025; }

.strayer .streaming .vjs-poster {
  background-color: #b92025;
  background-color: rgba(185, 32, 37, 0.8); }

.travel .vjs-big-play-button {
  background-color: #082340;
  background-color: rgba(8, 35, 64, 0.8); }

.travel .vjs-play-progress,
.travel .vjs-play-control.vjs-paused,
.travel .vjs-volume-level {
  background: #082340 no-repeat; }

.travel .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #082340;
  background-color: #082340; }

.travel .streaming .vjs-poster {
  background-color: #082340;
  background-color: rgba(8, 35, 64, 0.8); }

.tvshowbiz .vjs-big-play-button {
  background-color: #e01c44;
  background-color: rgba(224, 28, 68, 0.8); }

.tvshowbiz .vjs-play-progress,
.tvshowbiz .vjs-play-control.vjs-paused,
.tvshowbiz .vjs-volume-level {
  background: #e01c44 no-repeat; }

.tvshowbiz .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #e01c44;
  background-color: #e01c44; }

.tvshowbiz .streaming .vjs-poster {
  background-color: #e01c44;
  background-color: rgba(224, 28, 68, 0.8); }

.video .vjs-big-play-button {
  background-color: #004db4;
  background-color: rgba(0, 77, 180, 0.8); }

.video .vjs-play-progress,
.video .vjs-play-control.vjs-paused,
.video .vjs-volume-level {
  background: #004db4 no-repeat; }

.video .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #004db4;
  background-color: #004db4; }

.video .streaming .vjs-poster {
  background-color: #004db4;
  background-color: rgba(0, 77, 180, 0.8); }

.you .vjs-big-play-button {
  background-color: #d42699;
  background-color: rgba(212, 38, 153, 0.8); }

.you .vjs-play-progress,
.you .vjs-play-control.vjs-paused,
.you .vjs-volume-level {
  background: #d42699 no-repeat; }

.you .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #d42699;
  background-color: #d42699; }

.you .streaming .vjs-poster {
  background-color: #d42699;
  background-color: rgba(212, 38, 153, 0.8); }

.metro .vjs-big-play-button {
  background-color: #ffc000;
  background-color: rgba(255, 192, 0, 0.8); }

.metro .vjs-play-progress,
.metro .vjs-play-control.vjs-paused,
.metro .vjs-volume-level {
  background: #ffc000 no-repeat; }

.metro .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #ffc000;
  background-color: #ffc000; }

.metro .streaming .vjs-poster {
  background-color: #ffc000;
  background-color: rgba(255, 192, 0, 0.8); }

.home .vjs-big-play-button {
  background-color: #004db3;
  background-color: rgba(0, 77, 179, 0.8); }

.home .vjs-play-progress,
.home .vjs-play-control.vjs-paused,
.home .vjs-volume-level {
  background: #004db3 no-repeat; }

.home .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-principal-button {
  border-color: #004db3;
  background-color: #004db3; }

.home .streaming .vjs-poster {
  background-color: #004db3;
  background-color: rgba(0, 77, 179, 0.8); }

.vjs-poster {
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  background-color: #000; }

.vjs-has-started .vjs-poster {
  display: none; }

.vjs-slider {
  position: relative;
  /* background-color-with-alpha */
  background-color: #333;
  background-color: rgba(51, 51, 51, 0.9);
  outline: 0;
  cursor: pointer; }

.vjs-slider:focus {
  /* box-shadow */
  -webkit-box-shadow: 0 0 2em #fff;
  -moz-box-shadow: 0 0 2em #fff;
  box-shadow: 0 0 2em #fff; }

.vjs-slider-handle {
  position: absolute; }

.vjs-slider-handle:before {
  content: "w";
  font-family: MolVideo;
  font-size: 1em;
  line-height: 1em;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  color: #ffffff; }

/* Show the spinner when waiting for data and seeking to a new time
 * Important: only animate when showing because it can be processor heavy
 */
.vjs-ad-playing.vjs-vast-ad-loading .vjs-loading-spinner {
  /* The spinner in our player is different than the default one so, no
  animation on the loading spinner but on its child */
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  animation: none !important; }

div.vjs-loading-spinner {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000; }
  div.vjs-loading-spinner .vjs-loading-spinner-icon {
    font-family: MolVideo;
    font-size: 40px;
    line-height: 40px;
    height: 40px;
    opacity: 0.75;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
    /* animation */
    -webkit-animation: spin 1.5s infinite linear;
    -moz-animation: spin 1.5s infinite linear;
    -o-animation: spin 1.5s infinite linear;
    animation: spin 1.5s infinite linear; }
    div.vjs-loading-spinner .vjs-loading-spinner-icon:before {
      content: "v";
      display: block;
      padding: 2px 0 0 0 !important; }

.vjs-waiting .vjs-loading-spinner,
.vjs-seeking .vjs-loading-spinner {
  display: block; }

.vjs-playing .vjs-loading-spinner,
.vjs-paused .vjs-loading-spinner {
  display: none; }

.vjs-iphone .vjs-loading-spinner,
.vjs-iphone .vjs-loading-spinner,
.vjs-error .vjs-loading-spinner {
  display: none !important; }

/* animation mixins */
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(359deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

video::-webkit-media-controls {
  display: none !important; }

video.hideVideoForSocialShare {
  display: none; }

.vjs-menu-button {
  cursor: pointer; }

.vjs-menu {
  display: none; }

.vjs-menu-button .vjs-menu .vjs-menu-content {
  display: block;
  /* background-color-with-alpha */
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.7); }

.vjs-menu-button:hover .vjs-control-content .vjs-menu,
.vjs-control-content .vjs-menu.vjs-lock-showing {
  display: block; }

#vjs-post-video-content {
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 100px;
  text-align: center;
  width: 100%;
  height: 100%; }
  #vjs-post-video-content .poll-question {
    font-size: 2em;
    margin-bottom: 30px;
    font-weight: 700;
    color: #ffffff; }
  #vjs-post-video-content input {
    display: none; }
  #vjs-post-video-content .answer-label-first {
    margin-left: 15% !important;
    margin-left: 109px; }
  #vjs-post-video-content .answer-label {
    display: inline-block;
    float: left;
    width: 200px;
    line-height: 3em;
    margin: 0 10px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
    border-radius: 2px;
    cursor: pointer;
    word-wrap: break-word;
    background-color: #521752; }

.vjs-control {
  position: relative;
  outline: none; }

.vjs-timer-controls {
  float: left; }

.vjs-play-control,
.mol-previous-control,
.mol-skip-control,
.vjs-social-share-control,
.vjs-volume-menu-button,
.vjs-video-quality-control,
.vjs-fullscreen-control {
  float: left;
  width: 50px;
  height: 50px; }
  .vjs-play-control::before,
  .mol-previous-control::before,
  .mol-skip-control::before,
  .vjs-social-share-control::before,
  .vjs-volume-menu-button::before,
  .vjs-video-quality-control::before,
  .vjs-fullscreen-control::before {
    float: left;
    font-family: MolVideo;
    font-size: 24px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px; }
  .vjs-responsive .vjs-play-control,
  .vjs-span-one-col .video-js .vjs-play-control, .vjs-responsive
  .mol-previous-control,
  .vjs-span-one-col .video-js
  .mol-previous-control, .vjs-responsive
  .mol-skip-control,
  .vjs-span-one-col .video-js
  .mol-skip-control, .vjs-responsive
  .vjs-social-share-control,
  .vjs-span-one-col .video-js
  .vjs-social-share-control, .vjs-responsive
  .vjs-volume-menu-button,
  .vjs-span-one-col .video-js
  .vjs-volume-menu-button, .vjs-responsive
  .vjs-video-quality-control,
  .vjs-span-one-col .video-js
  .vjs-video-quality-control, .vjs-responsive
  .vjs-fullscreen-control,
  .vjs-span-one-col .video-js
  .vjs-fullscreen-control {
    width: 40px;
    height: 40px; }
    .vjs-responsive .vjs-play-control::before,
    .vjs-span-one-col .video-js .vjs-play-control::before, .vjs-responsive
    .mol-previous-control::before,
    .vjs-span-one-col .video-js
    .mol-previous-control::before, .vjs-responsive
    .mol-skip-control::before,
    .vjs-span-one-col .video-js
    .mol-skip-control::before, .vjs-responsive
    .vjs-social-share-control::before,
    .vjs-span-one-col .video-js
    .vjs-social-share-control::before, .vjs-responsive
    .vjs-volume-menu-button::before,
    .vjs-span-one-col .video-js
    .vjs-volume-menu-button::before, .vjs-responsive
    .vjs-video-quality-control::before,
    .vjs-span-one-col .video-js
    .vjs-video-quality-control::before, .vjs-responsive
    .vjs-fullscreen-control::before,
    .vjs-span-one-col .video-js
    .vjs-fullscreen-control::before {
      font-size: 20px;
      width: 40px;
      height: 40px;
      line-height: 40px; }

.vjs-social-share-control {
  float: right; }
  .vjs-popup-content .vjs-social-share-control {
    float: left; }

.vjs-control.vjs-volume-menu-button:before {
  font-size: 25px; }

.vjs-control:focus:before,
.vjs-control:hover:before {
  color: #fff; }

.vjs-control .vjs-control-text {
  /* hide-visually */
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.mol-disabled-control.vjs-control,
.mol-disabled-control.vjs-control:hover::before {
  color: #363636; }

.vjs-full-width-popup {
  width: 100%;
  height: 100%;
  background-color: #353434;
  text-align: center;
  z-index: 1000; }

.vjs-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 26px;
  height: 26px;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: #fff;
  background-color: #000;
  /* border-radius */
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1; }

.vjs-play-control {
  cursor: pointer; }
  .vjs-play-control::before {
    content: "f";
    text-shadow: none; }
  .vjs-paused.vjs-has-started:not(.vjs-scrubbing) .vjs-play-control,
  .vjs-paused.vjs-ended.vjs-video-finished .vjs-play-control {
    width: auto;
    min-width: 50px; }
    .vjs-responsive .vjs-paused.vjs-has-started:not(.vjs-scrubbing) .vjs-play-control,
    .vjs-span-one-col .video-js .vjs-paused.vjs-has-started:not(.vjs-scrubbing) .vjs-play-control, .vjs-responsive
    .vjs-paused.vjs-ended.vjs-video-finished .vjs-play-control,
    .vjs-span-one-col .video-js
    .vjs-paused.vjs-ended.vjs-video-finished .vjs-play-control {
      min-width: 40px; }
  .vjs-play-control::after {
    color: #fff;
    margin-left: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 800;
    padding: 0 10px 0 0;
    display: block; }
    .vjs-responsive .vjs-play-control::after,
    .vjs-span-one-col .video-js .vjs-play-control::after {
      font-size: 14px;
      padding: 0 10px 0 0;
      margin-left: 40px;
      line-height: 40px; }
    @media screen and (max-width: 635px) {
      .video-js.vjs-fullscreen.vjs-paused.vjs-has-started:not(.vjs-scrubbing) .vjs-play-control::after,
      .vjs-paused.vjs-has-started:not(.vjs-scrubbing) .vjs-play-control::after {
        content: 'Resume'; } }
    .vjs-span-one-col .video-js.vjs-paused.vjs-has-started:not(.vjs-scrubbing) .vjs-play-control::after {
      content: 'Resume'; }
    .vjs-paused.vjs-ended.vjs-video-finished .vjs-play-control::after {
      content: 'Watch again'; }
    @media screen and (max-width: 635px) {
      .video-js.vjs-fullscreen.vjs-paused.vjs-ended.vjs-video-finished .vjs-play-control::after,
      .vjs-paused.vjs-ended.vjs-video-finished .vjs-play-control::after {
        content: 'Watch again'; } }
  .vjs-has-started .vjs-play-control::before {
    color: #fff; }
  .vjs-playing .vjs-play-control::before {
    content: "e"; }
  .vjs-video-finished .vjs-play-control::before {
    content: "B"; }

.vjs-progress-control {
  position: absolute;
  bottom: 50px;
  font-size: 3px;
  width: 100%;
  height: 1em;
  /* transition */
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  -o-transition: height 0.4s;
  transition: height 0.4s; }
  .vjs-responsive .vjs-progress-control,
  .vjs-span-one-col .video-js .vjs-progress-control {
    bottom: 40px; }

.vjs-progress-holder {
  height: 100%; }

.vjs-play-progress,
.vjs-load-progress,
.vjs-load-progress div {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%; }

.vjs-play-progress {
  background: #66A8CC url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAP0lEQVQIHWWMAQoAIAgDR/QJ/Ub//04+w7ZICBwcOg5FZi5iBB82AGzixEglJrd4TVK5XUJpskSTEvpdFzX9AB2pGziSQcvAAAAAAElFTkSuQmCC") -50% 0 repeat; }

.vjs-load-progress {
  background: #646464;
  background: rgba(255, 255, 255, 0.2); }

.vjs-load-progress div {
  background: #787878;
  background: rgba(255, 255, 255, 0.1); }

.vjs-seek-handle {
  width: 1.5em;
  height: 100%; }

.video-js:not(.vjs-has-started) .vjs-progress-control,
.vjs-has-started.vjs-video-finished .vjs-progress-control {
  pointer-events: none;
  cursor: default;
  font-size: 3px; }

.vjs-timer-controls {
  width: auto;
  text-align: center;
  font-size: 14px;
  line-height: 50px; }
  .vjs-responsive .vjs-timer-controls,
  .vjs-span-one-col .video-js .vjs-timer-controls {
    height: 40px;
    font-size: 12px;
    line-height: 40px; }

.vjs-control-bar .vjs-current-time {
  margin-left: 10px; }

.vjs-control-bar .vjs-duration {
  margin-right: 10px; }

.vjs-control-bar .vjs-time-divider {
  margin: 0 5px; }

.vjs-current-time,
.vjs-duration,
.vjs-time-divider {
  float: left; }

.vjs-remaining-time {
  display: none;
  float: left; }

.vjs-ad-playing .vjs-timer-controls {
  border: 0; }

.vjs-volume-menu-button {
  float: left; }

.vjs-volume-menu-button:before {
  content: "d"; }

.vjs-volume-menu-button.vjs-vol-0:before {
  content: " a"; }

.vjs-volume-menu-button.vjs-vol-1:before {
  content: "b"; }

.vjs-volume-menu-button.vjs-vol-2:before {
  content: "c"; }

.vjs-volume-bar {
  width: 5em;
  height: 0.6em;
  margin: 1.1em auto 0; }

.vjs-volume-level {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5em;
  /* assuming volume starts at 1.0 */
  width: 100%;
  background: #66A8CC url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAP0lEQVQIHWWMAQoAIAgDR/QJ/Ub//04+w7ZICBwcOg5FZi5iBB82AGzixEglJrd4TVK5XUJpskSTEvpdFzX9AB2pGziSQcvAAAAAAElFTkSuQmCC") -50% 0 repeat; }

.vjs-volume-bar .vjs-volume-handle {
  width: 0.5em;
  height: 0.5em;
  /* Assumes volume starts at 1.0. If you change the size of the
     handle relative to the volume bar, you'll need to update this value
     too. */
  left: 4.5em; }

.vjs-volume-handle:before {
  font-size: 0.9em; }

/* The volume menu button is like menu buttons (captions/subtitles) but works
    a little differently. It needs to be possible to tab to the volume slider
    without hitting space bar on the menu button. To do this we're not using
    display:none to hide the slider menu by default, and instead setting the
    width and height to zero. */
.vjs-volume-menu-button .vjs-menu {
  display: block; }

.vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content {
  display: none;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
  /* transform */
  -webkit-transform: rotate(-90deg) translateX(-100px);
  -moz-transform: rotate(-90deg) translateX(-100px);
  -ms-transform: rotate(-90deg) translateX(-100px);
  -o-transform: rotate(-90deg) translateX(-100px);
  transform: rotate(-90deg) translateX(-100px);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  margin-left: 0;
  margin-top: -100px; }
  .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-bar {
    margin: 22px auto 0;
    width: 8.4em; }
  .vjs-responsive .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-menu-button,
  .vjs-span-one-col .video-js .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-menu-button {
    width: 40px;
    height: 40px; }
  .vjs-responsive .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-menu-button:before,
  .vjs-span-one-col .video-js .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-menu-button:before {
    font-size: 21px; }
  .vjs-responsive .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-bar,
  .vjs-span-one-col .video-js .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-bar {
    margin: 17px auto 0; }
  .vjs-responsive .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-menu-button:hover .vjs-menu .vjs-menu-content,
  .vjs-responsive .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-menu-button .vjs-menu.vjs-lock-showing .vjs-menu-content,
  .vjs-span-one-col .video-js .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-menu-button:hover .vjs-menu .vjs-menu-content,
  .vjs-span-one-col .video-js .vjs-control-bar .vjs-volume-menu-button .vjs-menu .vjs-menu-content .vjs-volume-menu-button .vjs-menu.vjs-lock-showing .vjs-menu-content {
    height: 40px; }

.vjs-volume-menu-button:hover .vjs-menu .vjs-menu-content,
.vjs-volume-menu-button .vjs-menu.vjs-lock-showing .vjs-menu-content {
  display: block;
  height: 50px;
  width: 10em;
  z-index: 9999; }

.vjs-paused.vjs-has-started .vjs-volume-menu-button {
  border-left: 1px solid transparent; }

.vjs-responsive .vjs-volume-menu-button {
  display: none; }

.vjs-video-quality-control {
  float: right;
  cursor: pointer; }
  .vjs-video-quality-control.active {
    background-color: #353434; }
    .vjs-video-quality-control.active:before {
      color: #fff; }
  .vjs-video-quality-control:before {
    content: "g"; }
  .vjs-ad-playing .vjs-video-quality-control {
    display: none !important; }
  .vjs-responsive .vjs-video-quality-control,
  .vjs-span-one-col .video-js .vjs-video-quality-control {
    display: none !important; }
  .vjs-video-quality-control .vjs-menu {
    display: block;
    visibility: hidden;
    border: none;
    height: auto;
    width: 120px;
    left: -70px;
    position: absolute;
    bottom: 50px;
    background-color: #353434;
    padding: 10px 0;
    opacity: 0.8;
    z-index: 9999; }
    .vjs-responsive .vjs-video-quality-control .vjs-menu,
    .vjs-span-one-col .video-js .vjs-video-quality-control .vjs-menu {
      bottom: 40px; }
    .vjs-video-quality-control .vjs-menu.vjs-lock-showing {
      visibility: visible; }
    .vjs-video-quality-control .vjs-menu .vjs-menu-content {
      text-align: left; }
      .vjs-video-quality-control .vjs-menu .vjs-menu-content .vjs-menu-title,
      .vjs-video-quality-control .vjs-menu .vjs-menu-content .vjs-menu-item {
        font-size: 14px;
        padding: 0 10px 0 15px;
        line-height: 20px; }
        .vjs-video-quality-control .vjs-menu .vjs-menu-content .vjs-menu-title:focus,
        .vjs-video-quality-control .vjs-menu .vjs-menu-content .vjs-menu-item:focus {
          outline: none; }
      .vjs-video-quality-control .vjs-menu .vjs-menu-content .vjs-menu-title {
        font-weight: bolder; }
      .vjs-video-quality-control .vjs-menu .vjs-menu-content .vjs-menu-item.vjs-selected, .vjs-video-quality-control .vjs-menu .vjs-menu-content .vjs-menu-item:hover {
        color: #fff;
        font-weight: bold; }

.mol-previous-control::before,
.mol-skip-control::before {
  font-size: 18px; }

.mol-previous-control:focus:not(:hover)::before,
.mol-skip-control:focus:not(:hover)::before {
  color: inherit; }

.mol-previous-control::before {
  content: "A";
  transform: rotate(180deg); }

.mol-skip-control::before {
  content: "A"; }

.vjs-paused.vjs-ended.vjs-video-finished .mol-previous-control,
.vjs-paused.vjs-ended.vjs-video-finished .mol-skip-control {
  display: none; }

.mol-previous-control,
.mol-skip-control {
  cursor: pointer; }
  .vjs-responsive .mol-previous-control,
  .vjs-span-one-col .video-js .mol-previous-control, .vjs-responsive
  .mol-skip-control,
  .vjs-span-one-col .video-js
  .mol-skip-control {
    display: none; }

.vjs-control-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #000;
  z-index: 1001; }
  .vjs-responsive .vjs-control-bar,
  .vjs-span-one-col .video-js .vjs-control-bar {
    height: 40px; }

.vjs-paused.vjs-has-started .vjs-progress-control {
  opacity: 0.4; }

@media only screen and (min-width: 964px) {
  .vjs-fullscreen .vjs-control-bar {
    width: 964px;
    left: 50%;
    margin-left: -482px; } }

.vjs-ad-playing .vjs-video-quality-control,
.vjs-controls-disabled .vjs-social-controls,
.vjs-error .vjs-control-bar {
  display: none; }

.vjs-volume-menu-button,
.vjs-timer-controls,
.vjs-social-controls,
.vjs-play-control,
.mol-skip-control,
.vjs-video-quality-control,
.vjs-fullscreen-control {
  border-left: 1px solid rgba(255, 255, 255, 0.2); }

.vjs-responsive .vjs-volume-menu-button,
.vjs-responsive .vjs-timer-controls,
.vjs-responsive .vjs-social-controls,
.vjs-responsive .vjs-video-quality-control,
.vjs-responsive .vjs-fullscreen-control {
  border-left: 0; }

/* STREAMING */
.streaming-label,
.streaming-override {
  display: none; }

.streaming.vjs-ad-playing .streaming-label
.streaming-override,
.streaming.vjs-ad-playing .vjs-social-controls
.vjs-timer-controls {
  display: none; }

.streaming .vjs-timer-controls,
.streaming .vjs-load-progress,
.streaming .vjs-play-progress,
.streaming .vjs-seek-handle,
.streaming .vjs-social-controls {
  display: none; }

.streaming .vjs-paused,
.streaming .vjs-play-control,
.streaming .vjs-play-control.vjs-paused {
  background: #EF2020 no-repeat !important;
  border-right: 1px solid #E5E5E5; }

.streaming .vjs-play-control.vjs-playing {
  background: #000000 no-repeat !important;
  border-right: none; }

.streaming .streaming-label {
  display: block;
  background-color: #EF2020;
  width: 71px;
  line-height: 52px;
  font-family: arial;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  float: left; }

.streaming .streaming-override-icon {
  display: block;
  width: 40px;
  height: 46px;
  background-color: black;
  position: absolute;
  z-index: 100;
  opacity: 0.6; }

.streaming .vjs-black-poster {
  display: none !important; }

.vjs-span-one-col .streaming .streaming-label {
  line-height: 42px; }

.vjs-span-one-col .streaming .streaming-override-icon {
  width: 33px; }

.vjs-video-container.vjs-fixed.vjs-span-one-col.vjs-video-container-streaming:before {
  padding-bottom: 56px; }

@media screen and (max-width: 636px) {
  .streaming .streaming-label {
    line-height: 42px; }
  .streaming .streaming-override-icon {
    width: 33px;
    height: 38px; } }

.vjs-video-container .video-js.vjs-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10050; }
  .vjs-video-container .video-js.vjs-fullscreen .vjs-title {
    /* transition */
    -webkit-transition: transform 200ms;
    -moz-transition: transform 200ms;
    -o-transition: transform 200ms;
    transition: transform 200ms; }
  .vjs-video-container .video-js.vjs-fullscreen.video-js {
    background-color: #000; }

.vjs-fullscreen.vjs-user-active .vjs-title {
  /* transform */
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.vjs-fullscreen-control {
  float: right;
  cursor: pointer; }
  .vjs-fullscreen-control::before {
    content: "t"; }
  .vjs-fullscreen .vjs-fullscreen-control::before {
    content: "u"; }
  .fbia .vjs-fullscreen-control {
    display: none !important; }

.no-full-width-control .vjs-fullscreen-control {
  display: none; }

.vjs-fullscreen.vjs-user-inactive {
  cursor: none; }

.vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1;
  visibility: visible; }

/* IE8 is flakey with fonts, and you have to change the actual content to force
fonts to show/hide properly.
  - "\9" IE8 hack didn't work for this
  - Found in XP IE8 from http://modern.ie. Does not show up in "IE8 mode" in IE9
*/
/* Move captions down when controls aren't being shown */
.vjs-user-inactive.vjs-playing .vjs-text-track-display {
  bottom: 1em; }

.vjs-user-active .vjs-progress-control {
  font-size: 10px; }

.vjs-user-active.vjs-ad-playing .vjs-progress-control {
  font-size: 3px; }

.vjs-error-display {
  display: none; }

.vjs-error .vjs-error-display {
  display: block; }

.vjs-error-display div {
  position: absolute;
  left: 20%;
  right: 20%;
  top: 40%;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  /* background-color-with-alpha */
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5); }

.vjs-error-display a, .vjs-error-display a:visited {
  color: #F4A460; }

/*  In IE8 w/ no JavaScript (no HTML5 shim), the video tag doesn't register.
    The .video-js classname on the video tag also isn't considered.
    This optional paragraph inside the video tag can provide a message to users
    about what's required to play video. */
.vjs-no-js {
  margin: 0 20%;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  color: #ccc;
  background-color: #333; }

.vjs-no-js a, .vjs-no-js a:visited {
  color: #F4A460; }

.vjs-social-icon {
  font-family: MolVideo;
  cursor: pointer;
  /* border-radius */
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.vjs-mail-button-icon:before {
  content: "h"; }

.vjs-embed-button-icon:before {
  content: "i"; }

.vjs-facebook-button-icon:before {
  content: "j"; }

.vjs-link-button-icon:before {
  content: "k"; }

.vjs-pinterest-button-icon:before {
  content: "l"; }

.vjs-share-button-icon:before {
  content: "m"; }

.vjs-twitter-button-icon:before {
  content: "n"; }

.vjs-google-plus-button-icon:before {
  content: "z"; }

.vjs-digg-button-icon:before {
  content: "o"; }

.vjs-linkedin-button-icon:before {
  content: "q"; }

.vjs-reddit-button-icon:before {
  content: "r"; }

.vjs-stumble-upon-button-icon:before {
  content: "s"; }

.vjs-fark-button-icon:before {
  content: "p"; }

.vjs-control.vjs-social-controls {
  overflow: hidden;
  float: right;
  width: auto;
  padding: 0 5px 0 10px; }
  .vjs-control.vjs-social-controls .vjs-social-share-control {
    width: 30px;
    margin: 0 5px 0 0; }
    .vjs-control.vjs-social-controls .vjs-social-share-control .vjs-social-icon {
      border: 1px solid #B1B1B1;
      font-size: 28px;
      line-height: 1em;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      margin-left: -15px;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
      .vjs-control.vjs-social-controls .vjs-social-share-control .vjs-social-icon::before {
        font-weight: lighter; }
      .vjs-control.vjs-social-controls .vjs-social-share-control .vjs-social-icon:hover {
        border-color: #fff; }
  .vjs-responsive .vjs-control.vjs-social-controls,
  .vjs-span-one-col .video-js .vjs-control.vjs-social-controls {
    max-width: calc(100% - 221px);
    padding: 0 5px 0 10px; }
    .vjs-responsive .vjs-control.vjs-social-controls .vjs-social-share-control,
    .vjs-span-one-col .video-js .vjs-control.vjs-social-controls .vjs-social-share-control {
      display: none;
      width: 25px;
      height: 40px;
      margin: 0 5px 0 0; }
      .vjs-responsive .vjs-control.vjs-social-controls .vjs-social-share-control .vjs-social-icon,
      .vjs-span-one-col .video-js .vjs-control.vjs-social-controls .vjs-social-share-control .vjs-social-icon {
        font-size: 23px;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 25px;
        height: 25px;
        margin-top: -12.5px;
        margin-left: -12.5px; }
      .vjs-responsive .vjs-control.vjs-social-controls .vjs-social-share-control.vjs-social-share-control-facebook-button, .vjs-responsive .vjs-control.vjs-social-controls .vjs-social-share-control.vjs-social-share-control-twitter-button, .vjs-responsive .vjs-control.vjs-social-controls .vjs-social-share-control.vjs-social-share-control-share-button, .vjs-responsive .vjs-control.vjs-social-controls .vjs-social-share-control.vjs-social-share-control-mail-button,
      .vjs-span-one-col .video-js .vjs-control.vjs-social-controls .vjs-social-share-control.vjs-social-share-control-facebook-button,
      .vjs-span-one-col .video-js .vjs-control.vjs-social-controls .vjs-social-share-control.vjs-social-share-control-twitter-button,
      .vjs-span-one-col .video-js .vjs-control.vjs-social-controls .vjs-social-share-control.vjs-social-share-control-share-button,
      .vjs-span-one-col .video-js .vjs-control.vjs-social-controls .vjs-social-share-control.vjs-social-share-control-mail-button {
        display: block; }

.vjs-full-width-popup {
  display: table;
  position: absolute !important;
  top: 0;
  left: 0; }
  .vjs-full-width-popup :focus {
    outline: none; }
  .vjs-full-width-popup .vjs-popup-title {
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    color: #fff; }
  .vjs-full-width-popup .vjs-popup-content {
    margin-bottom: 15px; }
    .vjs-full-width-popup .vjs-popup-content ::-webkit-input-placeholder {
      color: #B1B1B1; }
    .vjs-full-width-popup .vjs-popup-content :-moz-placeholder {
      color: #B1B1B1; }
    .vjs-full-width-popup .vjs-popup-content ::-moz-placeholder {
      color: #B1B1B1; }
    .vjs-full-width-popup .vjs-popup-content :-ms-input-placeholder {
      color: #B1B1B1; }
    .vjs-full-width-popup .vjs-popup-content input, .vjs-full-width-popup .vjs-popup-content textarea {
      background-color: transparent;
      border: solid thin #666666;
      overflow: hidden;
      color: #B1B1B1;
      font-size: 1.3em; }
    .vjs-full-width-popup .vjs-popup-content textarea {
      padding: 10px; }
  .vjs-full-width-popup .vjs-popup-helper-msg {
    font-size: 2.2em; }
  .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-button {
    padding: 10px;
    width: 110px;
    height: 35px;
    border: 1px solid #666666;
    font-size: 13px;
    line-height: 0.5em;
    vertical-align: middle;
    font-weight: normal;
    background-color: transparent;
    color: #ffffff;
    margin: 0 10px;
    /* border-radius */
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    cursor: pointer; }
    .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-button:hover, .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-button.zeroclipboard-is-hover {
      opacity: 0.7; }
    .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-button:active, .vjs-full-width-popup .vjs-popup-buttons .vjs-popup-button.zeroclipboard-is-active {
      opacity: 0.8;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15); }

.vjs-embed-popup-content .vjs-popup-content textarea,
.vjs-link-popup-content .vjs-popup-content textarea {
  width: 92%; }

.vjs-social-popup-content .vjs-popup-content {
  margin: 0 auto;
  overflow: hidden; }
  .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control .vjs-social-icon {
    width: 100%;
    height: 100%; }
    .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control .vjs-social-icon::before {
      width: 100%;
      height: 100%;
      text-align: center;
      display: block;
      line-height: 1; }

.vjs-responsive .vjs-social-popup-content .vjs-popup-content,
.vjs-span-one-col .video-js .vjs-social-popup-content .vjs-popup-content {
  max-width: 250px;
  padding: 10px 0 0 0; }
  .vjs-responsive .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control,
  .vjs-span-one-col .video-js .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control {
    margin: 0 10px 10px 0;
    width: 40px;
    height: 40px; }
    .vjs-responsive .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control .vjs-social-icon::before,
    .vjs-span-one-col .video-js .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control .vjs-social-icon::before {
      font-size: 40px; }

.vjs-span-two-col .video-js .vjs-social-popup-content .vjs-popup-content {
  max-width: 300px;
  padding: 10px 0 0 0; }
  .vjs-span-two-col .video-js .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control {
    margin: 0 10px 10px 0;
    width: 50px;
    height: 50px; }
    .vjs-span-two-col .video-js .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control .vjs-social-icon::before {
      font-size: 50px; }

.vjs-span-three-col .video-js .vjs-social-popup-content .vjs-popup-content {
  max-width: 450px;
  padding: 10px 0 0 0; }
  .vjs-span-three-col .video-js .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control {
    margin: 0 10px 10px 0;
    width: 80px;
    height: 80px; }
    .vjs-span-three-col .video-js .vjs-social-popup-content .vjs-popup-content .vjs-social-share-control .vjs-social-icon::before {
      font-size: 80px; }

.mol-related-videos-panel-social-list .vjs-social-icon,
.vjs-popup-content .vjs-social-icon {
  color: #fff; }
  .mol-related-videos-panel-social-list .vjs-social-icon:hover,
  .vjs-popup-content .vjs-social-icon:hover {
    opacity: 0.7; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-share-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-share-button-icon {
    background-color: #a2a2a2; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-facebook-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-facebook-button-icon {
    background-color: #3B5998; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-pinterest-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-pinterest-button-icon {
    background-color: #EA1514; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-twitter-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-twitter-button-icon {
    background-color: #28AAE1; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-mail-button-icon, .mol-related-videos-panel-social-list .vjs-social-icon.vjs-link-button-icon, .mol-related-videos-panel-social-list .vjs-social-icon.vjs-embed-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-mail-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-link-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-embed-button-icon {
    background-color: #441543; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-google-plus-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-google-plus-button-icon {
    background-color: #D34836;
    color: #fff; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-linkedin-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-linkedin-button-icon {
    background-color: #4875B4;
    color: #fff; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-digg-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-digg-button-icon {
    background-color: #474747;
    color: #fff; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-reddit-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-reddit-button-icon {
    background-color: #ffffff;
    color: #000000; }
    .mol-related-videos-panel-social-list .vjs-social-icon.vjs-reddit-button-icon:hover:before,
    .vjs-popup-content .vjs-social-icon.vjs-reddit-button-icon:hover:before {
      color: inherit; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-fark-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-fark-button-icon {
    background-color: #e3e3e3;
    color: #177bc9; }
  .mol-related-videos-panel-social-list .vjs-social-icon.vjs-stumble-upon-button-icon,
  .vjs-popup-content .vjs-social-icon.vjs-stumble-upon-button-icon {
    background-color: #eb4823;
    color: #fff; }

.vjs-popup-wrapper {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  top: -25px; }
  .vjs-responsive .vjs-popup-wrapper,
  .vjs-span-one-col .video-js .vjs-popup-wrapper {
    top: -20px; }

.vjs-big-play-button {
  display: block;
  position: absolute;
  top: 36px;
  right: 0;
  width: 100px;
  height: 100px;
  text-align: center;
  cursor: pointer; }
  .vjs-big-play-button:before {
    content: "f";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-family: MolVideo;
    font-size: 50px;
    line-height: 100px;
    color: #fff;
    text-align: center; }
  .vjs-paused.vjs-has-started .vjs-big-play-button,
  .vjs-playing .vjs-big-play-button,
  .vjs-ad-playing .vjs-big-play-button,
  .vjs-video-finished .vjs-big-play-button,
  .vjs-using-native-controls .vjs-big-play-button,
  .vjs-error .vjs-big-play-button {
    display: none; }
  .vjs-responsive .vjs-big-play-button,
  .vjs-span-one-col .video-js .vjs-big-play-button {
    top: 44px;
    width: 60px;
    height: 60px; }
    .vjs-responsive .vjs-big-play-button:before,
    .vjs-span-one-col .video-js .vjs-big-play-button:before {
      font-size: 30px;
      line-height: 60px; }
    .vjs-responsive .vjs-big-play-button .vjs-has-started.vjs-paused .vjs-big-play-button,
    .vjs-span-one-col .video-js .vjs-big-play-button .vjs-has-started.vjs-paused .vjs-big-play-button {
      width: 150px; }
      .vjs-responsive .vjs-big-play-button .vjs-has-started.vjs-paused .vjs-big-play-button:before,
      .vjs-span-one-col .video-js .vjs-big-play-button .vjs-has-started.vjs-paused .vjs-big-play-button:before {
        top: 0;
        left: 5px;
        width: 60px; }
  .vjs-video-container.vjs-one-col-title .video-js .vjs-big-play-button {
    top: 44px; }

.vjs-ad-playing.vjs-paused .vjs-big-play-button {
  display: block; }

.vjs-video-container .video-js.vjs-fullscreen .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin: -50px 0 -50px; }

.vjs-iphone.vjs-ios-old .vjs-big-play-button,
.vjs-ad-playing.vjs-paused.vjs-iphone.vjs-ios-old .vjs-big-play-button {
  display: none; }

#mobileGalleryModal #mobileGallerySlider .slide-container .vjs-responsive .video-js .vjs-big-play-button {
  top: 44px;
  left: initial;
  right: 0;
  margin: 0; }

.vjs-disable-social-linkedin .vjs-social-share-control-linkedin-button,
.vjs-disable-social-digg .vjs-social-share-control-digg-button,
.vjs-disable-social-fark .vjs-social-share-control-fark-button,
.vjs-disable-social-stumble-upon .vjs-social-share-control-stumble-upon-button,
.vjs-disable-social-reddit .vjs-social-share-control-reddit-button,
.vjs-disable-social-google-plus .vjs-social-share-control-google-plus-button,
.vjs-disable-social-link .vjs-social-share-control-link-button,
.vjs-disable-social-embed .vjs-social-share-control-embed-button,
.vjs-disable-social-mail .vjs-social-share-control-mail-button,
.vjs-disable-social-pinterest .vjs-social-share-control-pinterest-button,
.vjs-disable-social-facebook .vjs-social-share-control-facebook-button,
.vjs-disable-social-twitter .vjs-social-share-control-twitter-button {
  display: none !important; }

.video-js .vjs-title {
  position: absolute;
  height: 36px;
  font-size: 18px;
  line-height: 36px;
  width: 100%;
  overflow: hidden;
  color: #fff;
  background: #000;
  z-index: 1;
  font-weight: 600;
  box-sizing: border-box !important; }
  .vjs-playing .video-js .vjs-title {
    opacity: 0.8; }

.vjs-title-text {
  padding: 0 10px; }
  .vjs-responsive .vjs-title-text,
  .vjs-span-one-col .video-js .vjs-title-text {
    padding: 0px 8px; }

.vjs-responsive .vjs-title,
.vjs-span-one-col .video-js .vjs-title {
  display: table;
  height: 44px;
  font-size: 14px;
  line-height: 19px;
  overflow: hidden;
  white-space: normal; }
  .vjs-responsive .vjs-title > div,
  .vjs-span-one-col .video-js .vjs-title > div {
    vertical-align: middle;
    display: table-cell; }
    .vjs-responsive .vjs-title > div > div,
    .vjs-span-one-col .video-js .vjs-title > div > div {
      display: -webkit-box;
      height: 44px;
      padding-top: 4px;
      box-sizing: border-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }

.vjs-title.vjs-title-dmtv {
  display: table; }
  .vjs-title.vjs-title-dmtv > div {
    vertical-align: middle;
    display: table-cell; }
    .vjs-title.vjs-title-dmtv > div > div {
      height: 36px;
      line-height: 36px;
      box-sizing: border-box;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical; }
  .vjs-title.vjs-title-dmtv div.dmtv {
    height: 100%;
    width: 124px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f5f5f5;
    background-image: url("assets/dailymailtv-single-deck.svg"); }
  .vjs-responsive .vjs-title.vjs-title-dmtv > div > div,
  .vjs-span-one-col .video-js .vjs-title.vjs-title-dmtv > div > div {
    line-height: 19px; }
  .vjs-responsive .vjs-title.vjs-title-dmtv div.dmtv,
  .vjs-span-one-col .video-js .vjs-title.vjs-title-dmtv div.dmtv {
    width: 78px;
    background-image: url("assets/dailymailtv-stack.svg"); }

.vjs-one-col-title .vjs-fixed.vjs-span-one-col .video-js .vjs-title {
  display: table; }
  .vjs-one-col-title .vjs-fixed.vjs-span-one-col .video-js .vjs-title > div {
    height: inherit;
    vertical-align: middle;
    text-align: left;
    display: table-cell; }

#mobileGalleryModal #mobileGallerySlider .slide-container .vjs-responsive .video-js .vjs-title {
  top: 0;
  text-align: left; }

.video-js .hidden {
  display: none; }

.clearfix:after {
  content: " ";
  /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

div.vjs-ads-label.vjs-control {
  font-size: 14px;
  line-height: 50px;
  font-weight: bold;
  padding-left: 1em;
  width: auto;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  display: none;
  float: left; }

.vjs-ad-playing .vjs-control.vjs-ads-label {
  display: block; }

.vjs-responsive .vjs-ads-label.vjs-control,
.vjs-span-one-col .video-js .vjs-ads-label.vjs-control {
  font-size: 12px;
  line-height: 40px; }

.vjs-black-poster {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  cursor: pointer;
  background-color: #000; }

.vjs-has-started.vjs-vast-finish .vjs-black-poster {
  display: none; }

/* Fix for the ads full screen */
.vjs-video-container .vjs-ad-playing.vjs-fullscreen .vjs-title {
  display: none !important; }

.vjs-video-container .vjs-ad-playing.vjs-fullscreen .VPAID-container {
  top: 0;
  bottom: 0;
  margin: 0;
  height: 100%; }

#mobileGallerySlider .slide-container .ima-ad-container {
  text-align: left; }
  #mobileGallerySlider .slide-container .ima-ad-container video {
    position: relative !important;
    top: 0 !important;
    bottom: 0 !important; }

.vjs-flyout-side + .vjs-flyout-wrapper-fixed .vjs-video-container.vjs-fixed.vjs-span-one-col .ima-ad-container video + div {
  top: 25px;
  bottom: 40px;
  height: calc(100% - 65px); }

.vjs-one-col-title .ima-ad-container {
  background-color: black;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 0; }

.ima-ad-container {
  visibility: hidden;
  width: 100%;
  height: 100%; }

.ima-ad-container.ima-ad-playing,
.ima-ad-container.ima-ad-loading {
  visibility: visible; }

.ima-ad-container.ima-ad-loading {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black; }

.ima-ad-container iframe {
  width: 100% !important; }

.ima-ad-container .ima-loading-spinner {
  /* Should be hidden by default */
  display: none;
  /* ensure animation doesn't continue while hidden */
  animation: none;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 40px;
  line-height: 40px;
  width: 1em;
  height: 40px;
  margin-left: -0.5em;
  margin-top: -0.5em;
  opacity: 0.75; }

.ima-ad-container.ima-ad-loading .ima-loading-spinner {
  display: block;
  font-family: MolVideo;
  /* only animate when showing because it can be processor heavy */
  animation: spin 1.5s infinite linear; }

.ima-ad-container .ima-loading-spinner:before {
  content: "v";
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  text-align: center;
  text-shadow: 0em 0em 0.1em #000; }

.ima-ad-container.ima-ad-loading .ima-controls-div,
.ima-ad-container.ima-ad-playing .ima-controls-div {
  display: block; }

.vjs-fullscreen .ima-ad-container .ima-controls-div {
  bottom: 53px; }

.ima-ad-container .ima-controls-div {
  border-left: none;
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  z-index: 1001;
  opacity: 1;
  background-color: #000;
  background: -moz-linear-gradient(bottom, rgba(7, 20, 30, 0.7) 0%, rgba(7, 20, 30, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0%, rgba(7, 20, 30, 0.7)), color-stop(100%, rgba(7, 20, 30, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom, rgba(7, 20, 30, 0.7) 0%, rgba(7, 20, 30, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom, rgba(7, 20, 30, 0.7) 0%, rgba(7, 20, 30, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom, rgba(7, 20, 30, 0.7) 0%, rgba(7, 20, 30, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to top, rgba(7, 20, 30, 0.7) 0%, rgba(7, 20, 30, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0007141E', endColorstr='#07141E', GradientType=0);
  /* IE6-9 */ }

.ima-control.ima-controls-div {
  border-left: none; }

.ima-control {
  cursor: pointer;
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: top;
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.2); }

.ima-control:before {
  position: absolute;
  left: 50%;
  top: 0;
  width: 1em;
  height: 100%;
  line-height: 50px;
  margin-left: -0.5em;
  font-family: MolVideo;
  font-size: 24px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); }

.ima-control:hover:before {
  text-shadow: 0 0 1em #fff; }

.ima-play-pause-div:before {
  content: "e"; }

.ima-previous-div {
  border-left: none; }

.ima-previous-div:before {
  content: "A";
  transform: rotate(180deg); }

.ima-skip-div:before {
  content: "A"; }

.ima-ad-container .ima-previous-div:before,
.ima-ad-container .ima-skip-div:before {
  font-size: 18px; }

.ima-play:before {
  content: "f"; }

.ima-fullscreen-div {
  float: right; }

.ima-fullscreen-div:before {
  content: "t"; }

.vjs-fullscreen .ima-fullscreen-div:before {
  content: "u"; }

.ima-seek-bar-div {
  height: 3px;
  background-color: rgba(51, 51, 51, 0.9);
  position: absolute;
  bottom: 50px;
  width: 100%; }

.ima-progress-div {
  height: 100%;
  width: 0;
  background-color: yellow; }

.ima-control.ima-countdown-div {
  width: auto;
  padding-left: 10px;
  font-size: 14px;
  line-height: 50px;
  color: #FFFFFF;
  text-shadow: 0 0 0.2em #000; }

.mol-disabled-control.ima-control,
.mol-disabled-control.ima-control:hover::before {
  color: #363636;
  text-shadow: 0 0 0; }

.ima-control.ima-countdown-div {
  cursor: default; }

.ima-volume-div {
  position: relative;
  border-left: none; }

.ima-volume-slider {
  position: absolute;
  height: 100px;
  bottom: 50px;
  width: 50px;
  box-sizing: border-box;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.7); }

.ima-ad-container .ima-volume-slider {
  padding: 8px; }

.ima-volume-slider {
  display: none; }

.ima-volume-div:hover .ima-volume-slider,
.ima-volume-interact .ima-volume-slider {
  display: block; }

.ima-volume-track, .ima-volume-progress {
  width: 0.6em; }

.ima-volume-track {
  cursor: pointer;
  position: relative;
  bottom: 0;
  left: 50%;
  height: 100%;
  background-color: #333;
  background-color: rgba(51, 51, 51, 0.9); }

.ima-ad-container .ima-volume-track {
  margin-left: -0.3em; }

.ima-volume-progress {
  position: absolute;
  bottom: 0;
  height: 100%;
  background-color: yellow; }

.ima-volume-handler {
  position: absolute;
  bottom: 100%;
  left: 0.3em;
  background: red;
  width: 0;
  height: 0; }
  .ima-volume-handler:before {
    color: white;
    content: 'w';
    font-family: MolVideo;
    line-height: 1em;
    position: absolute;
    font-size: 1em;
    left: -.55em;
    top: -.5em; }

.ima-sound:before,
.ima-sound--loud:before {
  content: "d"; }

.ima-sound--medium:before {
  content: "c"; }

.ima-sound--low:before {
  content: "b"; }

.ima-sound--muted:before {
  content: "a"; }

.video-js.ima-ad-playing {
  padding-bottom: 53px; }

.video-js.ima-ad-playing .vjs-big-play-button {
  visibility: hidden; }

.fbia .ima-control.ima-fullscreen-div {
  display: none; }

.gamp .ima-control.ima-fullscreen-div {
  display: none; }

.video-js.ima-ad-playing {
  padding: inherit; }

.vjs-idevice .ima-volume-div {
  display: none; }

@media only screen and (min-width: 964px) {
  .video-js.ima-ad-playing.vjs-fullscreen .ima-ad-container .ima-controls-div {
    width: 964px !important;
    left: 50%;
    margin-left: -482px; } }

.video-js.ima-ad-playing .vjs-poster,
.video-js.ima-ad-playing .vjs-control-bar {
  visibility: hidden; }

.video-js.ima-ad-playing .ima-ad-container {
  padding-top: 35px;
  box-sizing: border-box;
  height: 100%; }

.ima-ad-container .ima-controls-div {
  height: 50px;
  background-color: #000 !important;
  width: 100% !important; }

.ima-ad-container .ima-control.ima-countdown-div {
  font-size: 14px;
  line-height: 50px; }

.ima-ad-container .ima-control:before {
  line-height: 50px;
  font-size: 24px; }

.vjs-responsive .video-js.ima-ad-playing .ima-ad-container,
.vjs-span-one-col .video-js .video-js.ima-ad-playing .ima-ad-container {
  padding-top: 44px;
  position: relative; }
  .vjs-responsive .video-js.ima-ad-playing .ima-ad-container .ima-controls-div,
  .vjs-span-one-col .video-js .video-js.ima-ad-playing .ima-ad-container .ima-controls-div {
    height: 40px;
    bottom: 0; }
  .vjs-responsive .video-js.ima-ad-playing .ima-ad-container .ima-control,
  .vjs-span-one-col .video-js .video-js.ima-ad-playing .ima-ad-container .ima-control {
    border-left: 0;
    width: 40px;
    height: 40px; }
  .vjs-responsive .video-js.ima-ad-playing .ima-ad-container .ima-volume-slider,
  .vjs-span-one-col .video-js .video-js.ima-ad-playing .ima-ad-container .ima-volume-slider {
    width: 40px;
    bottom: 40px; }
  .vjs-responsive .video-js.ima-ad-playing .ima-ad-container .ima-control:before,
  .vjs-span-one-col .video-js .video-js.ima-ad-playing .ima-ad-container .ima-control:before {
    line-height: 40px;
    font-size: 20px; }
  .vjs-responsive .video-js.ima-ad-playing .ima-ad-container .ima-control.ima-countdown-div,
  .vjs-span-one-col .video-js .video-js.ima-ad-playing .ima-ad-container .ima-control.ima-countdown-div {
    font-size: 12px;
    line-height: 40px; }

.ima-ad-container .ima-previous-div:before,
.ima-ad-container .ima-skip-div:before {
  font-size: 18px; }

.vjs-responsive .ima-seek-bar-div,
.vjs-span-one-col .video-js .ima-seek-bar-div {
  bottom: 40px; }

.video-js.ima-ad-playing .ima-ad-container video,
.video-js.ima-ad-playing .ima-ad-container object,
.video-js.ima-ad-playing .ima-ad-container embed {
  background: #000; }

.vjs-flyout-on + .vjs-flyout-wrapper-fixed {
  position: fixed;
  left: 0;
  z-index: 10000;
  width: 100%;
  transition: top 200ms; }

.vjs-flyout-on.vjs-flyout-side + .vjs-flyout-wrapper-fixed .vjs-fixed {
  width: 464px !important; }
  .vjs-flyout-on.vjs-flyout-side + .vjs-flyout-wrapper-fixed .vjs-fixed .ima-seek-bar-div {
    bottom: 50px !important; }

.vjs-flyout-on .video-js {
  background-color: transparent; }

.vjs-flyout-on.vjs-flyout-side + .vjs-flyout-wrapper-fixed {
  bottom: 330px; }

.vjs-flyout-on.vjs-flyout-center + .vjs-flyout-wrapper-fixed {
  top: 20px; }

@media (max-width: 1282px) {
  .floating-buttons-oop-ads.floating-buttons-show .vjs-flyout-on.vjs-flyout-side + .vjs-flyout-wrapper-fixed {
    top: 20px; } }

.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-flyout-wrapper-absolute {
  position: relative; }

.vjs-flyout-on.vjs-flyout-center + .vjs-flyout-wrapper-fixed .vjs-flyout-wrapper-absolute {
  width: 964px;
  margin: 0 auto; }

.ramjet .vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-flyout-wrapper-absolute {
  width: auto;
  margin: 0; }

.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-flyout-wrapper-absolute > * {
  position: absolute; }

.vjs-flyout-on.vjs-flyout-side + .vjs-flyout-wrapper-fixed .vjs-flyout-wrapper-absolute > * {
  right: 5px; }

.vjs-flyout-on.vjs-flyout-center + .vjs-flyout-wrapper-fixed .vjs-flyout-wrapper-absolute > * {
  left: 0; }

.vjs-video-container.vjs-flyout-placeholder {
  position: absolute;
  visibility: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain; }

.vjs-flyout-placeholder-content {
  display: none;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  width: 400px !important;
  height: 28px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: 0.7; }
  .vjs-flyout-center .vjs-flyout-placeholder-content {
    width: 305px !important; }
  .vjs-flyout-placeholder-content span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite; }
  .vjs-flyout-placeholder-content span:nth-child(1) {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s; }
  .vjs-flyout-placeholder-content span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
  .vjs-flyout-placeholder-content span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }

@-webkit-keyframes ellipsis-dot {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ellipsis-dot {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.vjs-flyout-placeholder.vjs-flyout-on {
  visibility: visible;
  position: relative;
  z-index: 1000 !important; }
  .vjs-flyout-placeholder.vjs-flyout-on::before {
    background-color: rgba(0, 0, 0, 0.8); }
  .vjs-flyout-placeholder.vjs-flyout-on .vjs-flyout-placeholder-content {
    display: block; }

/*components*/
.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-flyout-control {
  display: block; }

.vjs-flyout-center + .vjs-flyout-wrapper-fixed .vjs-flyout-control {
  height: 36px; }

.vjs-flyout-center + .vjs-flyout-wrapper-fixed .vjs-title {
  padding-right: 150px !important; }

.vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed {
  /* one col layout overrides */ }
  .vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-flyout-control {
    height: 25px; }
  .vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-video-container:before {
    padding-bottom: 65px !important;
    /* control bar and no title*/ }
  .vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-video-container video,
  .vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-video-container object,
  .vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-video-container .VPAID-container,
  .vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-video-container .ima-ad-container.ima-ad-playing > div:first-child {
    top: 25px;
    bottom: 40px;
    position: absolute;
    margin: auto; }
  .vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-video-container .VPAID-container > * {
    height: 100%;
    width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute; }
  .vjs-flyout-side.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-big-play-button {
    top: 25px; }

.vjs-flyout-control {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  background-color: #000;
  text-align: right; }

/*buttons*/
.vjs-flyout-button {
  display: inline-block;
  background-color: #000;
  color: #c0c0c0;
  font-size: 13px;
  cursor: pointer;
  margin-top: 4px;
  margin-left: 6px;
  margin-right: 2px; }

.vjs-flyout-minimize,
.vjs-flyout-expand {
  display: none; }

.vjs-flyout-button:after {
  float: right;
  padding-left: 6px; }

.vjs-flyout-minimize:after {
  font-family: MolVideo;
  content: 'u'; }

.vjs-flyout-expand:after {
  font-family: MolVideo;
  content: 't'; }

.vjs-flyout-close:after {
  font-size: 16px;
  content: '×'; }

.vjs-flyout-side + .vjs-flyout-wrapper-fixed .vjs-flyout-expand {
  display: inline-block; }

.vjs-flyout-center + .vjs-flyout-wrapper-fixed .vjs-flyout-minimize {
  display: inline-block; }

.vjs-flyout-on + .vjs-flyout-wrapper-fixed .vjs-flyout-wrapper-absolute .vjs-title {
  display: none !important; }

.vjs-flyout-side + .vjs-flyout-wrapper-fixed .vjs-video-container.vjs-fixed.vjs-span-one-col .mol-modal-window-backdrop {
  top: 25px;
  bottom: 40px;
  height: calc(100% - 61px); }

.mol-modal-window-backdrop {
  position: absolute;
  top: 36px;
  right: 0;
  bottom: 50px;
  left: 0;
  height: calc(100% - $control-bar-height);
  z-index: 900;
  transform-style: preserve-3d; }
  .mol-modal-window-backdrop .mol-modal-window {
    width: 100%;
    height: 100%;
    max-width: 740px;
    max-height: 505px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .vjs-responsive .mol-modal-window-backdrop,
  .vjs-span-one-col .video-js .mol-modal-window-backdrop {
    top: 44px;
    bottom: 40px;
    height: calc(100% - 84px); }
    .vjs-responsive .mol-modal-window-backdrop .mol-modal-window,
    .vjs-span-one-col .video-js .mol-modal-window-backdrop .mol-modal-window {
      max-height: 250px; }

.mol-related-videos-video .video-caption {
  box-sizing: content-box; }
  .mol-related-videos-video .video-caption .aux-stadiometer {
    height: 100%; }

.mol-related-videos-view-paused .mol-modal-window-backdrop {
  background: rgba(0, 0, 0, 0.8); }

.mol-related-videos-view-paused .mol-related-videos-view-paused-backdrop {
  background: no-repeat 50% 50%;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 36px;
  bottom: 50px;
  right: 0;
  z-index: 800; }
  .vjs-responsive .mol-related-videos-view-paused .mol-related-videos-view-paused-backdrop,
  .vjs-span-one-col .video-js .mol-related-videos-view-paused .mol-related-videos-view-paused-backdrop {
    bottom: 40px; }
  .vjs-responsive .mol-related-videos-view-paused .mol-related-videos-view-paused-backdrop,
  .vjs-one-col-title .mol-related-videos-view-paused .mol-related-videos-view-paused-backdrop {
    top: 44px; }

.mol-related-videos-panel-paused,
.mol-related-videos-panel-social,
.mol-related-videos-panel-autoplay {
  color: #fff; }
  .mol-related-videos-panel-paused > .panel-title,
  .mol-related-videos-panel-social > .panel-title,
  .mol-related-videos-panel-autoplay > .panel-title {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 5px;
    text-transform: uppercase;
    margin: 0;
    display: block;
    font-weight: bold;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased; }

.mol-related-videos-panel-social {
  overflow: hidden; }
  .mol-related-videos-panel-social .vjs-social-share-control .vjs-social-icon {
    width: 100%;
    height: 100%; }
    .mol-related-videos-panel-social .vjs-social-share-control .vjs-social-icon::before {
      width: 100%;
      height: 100%;
      text-align: center;
      display: block;
      line-height: 1; }
  .vjs-responsive .mol-related-videos-panel-social,
  .vjs-span-one-col .video-js .mol-related-videos-panel-social {
    font-size: 0;
    text-align: center;
    margin: 8px 20px;
    height: 40px; }
    .vjs-responsive .mol-related-videos-panel-social .panel-title,
    .vjs-span-one-col .video-js .mol-related-videos-panel-social .panel-title {
      display: none; }
    .vjs-responsive .mol-related-videos-panel-social .vjs-social-share-control .vjs-social-icon::before,
    .vjs-span-one-col .video-js .mol-related-videos-panel-social .vjs-social-share-control .vjs-social-icon::before {
      font-size: 40px; }
    .vjs-responsive .mol-related-videos-panel-social .vjs-social-share-control,
    .vjs-span-one-col .video-js .mol-related-videos-panel-social .vjs-social-share-control {
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin: 0 5px;
      display: inline-block;
      float: none; }
    @media screen and (max-width: 350px) {
      .vjs-responsive .mol-related-videos-panel-social,
      .vjs-span-one-col .video-js .mol-related-videos-panel-social {
        margin: 10px 20px; } }
  .vjs-span-two-col .video-js .mol-related-videos-panel-social,
  .vjs-span-three-col .video-js .mol-related-videos-panel-social {
    position: absolute;
    top: 0;
    left: 20px; }
    .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control,
    .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control {
      overflow: hidden; }
      .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(1), .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(3),
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(1),
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(3) {
        margin-right: 10px; }
      .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(1), .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(2), .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(3), .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(4),
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(1),
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(2),
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(3),
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(4) {
        margin-bottom: 10px; }
      .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(5)::before,
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(5)::before {
        content: 'More';
        text-transform: uppercase;
        font: inherit;
        font-weight: bold;
        float: right; }
  .vjs-span-two-col .video-js .mol-related-videos-panel-social {
    width: 160px; }
    .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control {
      float: left;
      width: 75px;
      height: 75px; }
      .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control .vjs-social-icon::before {
        font-size: 75px;
        line-height: 75px; }
      .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control .vjs-social-icon.vjs-mail-button-icon::before {
        font-size: 60px; }
      .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(5) {
        width: 160px; }
        .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(5) .vjs-social-icon::before {
          width: 75px; }
        .vjs-span-two-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(5)::before {
          font-size: 18px;
          line-height: 75px;
          width: 75px; }
  .vjs-span-three-col .video-js .mol-related-videos-panel-social {
    width: 210px; }
    .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control {
      float: left;
      width: 100px;
      height: 100px; }
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control .vjs-social-icon::before {
        font-size: 100px;
        line-height: 100px; }
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control .vjs-social-icon.vjs-mail-button-icon::before {
        font-size: 80px; }
      .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(5) {
        width: 210px; }
        .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(5) .vjs-social-icon::before {
          width: 100px; }
        .vjs-span-three-col .video-js .mol-related-videos-panel-social .vjs-social-share-control:nth-child(5)::before {
          font-size: 18px;
          line-height: 100px;
          width: 100px; }

.mol-related-videos-panel-paused .mol-related-videos-video {
  cursor: pointer; }
  .mol-related-videos-panel-paused .mol-related-videos-video .video-thumbnail {
    background: #000; }
    .mol-related-videos-panel-paused .mol-related-videos-video .video-thumbnail img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden; }

.vjs-responsive .mol-related-videos-panel-paused,
.vjs-span-one-col .video-js .mol-related-videos-panel-paused {
  margin: 0 20px;
  overflow: hidden; }
  .vjs-responsive .mol-related-videos-panel-paused .panel-title,
  .vjs-span-one-col .video-js .mol-related-videos-panel-paused .panel-title {
    display: none; }
  .vjs-responsive .mol-related-videos-panel-paused .mol-related-videos-video,
  .vjs-span-one-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video {
    clear: both;
    margin: 0 0 10px 0; }
    .vjs-responsive .mol-related-videos-panel-paused .mol-related-videos-video .video-thumbnail,
    .vjs-span-one-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-thumbnail {
      width: 80px;
      height: 50px;
      float: left; }
    .vjs-responsive .mol-related-videos-panel-paused .mol-related-videos-video .video-caption,
    .vjs-span-one-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-caption {
      overflow: hidden;
      height: 40px;
      padding: 5px; }
      .vjs-responsive .mol-related-videos-panel-paused .mol-related-videos-video .video-caption h4,
      .vjs-span-one-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-caption h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        margin: 0; }
    .vjs-responsive .mol-related-videos-panel-paused .mol-related-videos-video:nth-child(n+3),
    .vjs-span-one-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video:nth-child(n+3) {
      display: none; }

.vjs-span-two-col .video-js .mol-related-videos-panel-paused,
.vjs-span-three-col .video-js .mol-related-videos-panel-paused {
  position: absolute;
  width: 380px;
  top: 0;
  right: 20px; }
  .vjs-span-two-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video,
  .vjs-span-three-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video {
    width: 185px;
    height: 150px;
    float: left;
    margin: 0 0 10px; }
    .vjs-span-two-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-thumbnail,
    .vjs-span-three-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-thumbnail {
      height: 100px; }
    .vjs-span-two-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-caption,
    .vjs-span-three-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-caption {
      overflow: hidden;
      height: 40px;
      padding: 5px 0; }
      .vjs-span-two-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-caption h4,
      .vjs-span-three-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video .video-caption h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        margin: 0; }
    .vjs-span-two-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video:nth-child(2n-1),
    .vjs-span-three-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video:nth-child(2n-1) {
      margin-right: 10px; }

.vjs-span-two-col .video-js .mol-related-videos-panel-paused .mol-related-videos-video:nth-child(n+5) {
  display: none; }

.mol-related-videos-view-ended .mol-modal-window-backdrop {
  background: #000; }

.mol-related-videos-view-ended .mol-related-videos-panel-ended {
  position: absolute;
  left: 10px;
  top: 10px;
  right: 0;
  bottom: 0;
  color: #fff; }
  .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video {
    cursor: pointer;
    float: left;
    overflow: hidden;
    margin: 0 10px 10px 0;
    position: relative; }
    .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video .video-thumbnail {
      background: #000;
      height: 100%; }
      .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video .video-thumbnail img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden; }
    .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video .video-caption {
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 25px;
      background-color: rgba(0, 0, 0, 0.7); }
      .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video .video-caption h4 {
        font-size: 14px;
        line-height: 25px;
        font-weight: normal;
        padding: 0 5px;
        margin: 0;
        overflow: hidden; }
      .vjs-responsive .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video .video-caption,
      .vjs-span-one-col .video-js .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video .video-caption {
        height: 50%; }
        .vjs-responsive .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video .video-caption h4,
        .vjs-span-one-col .video-js .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video .video-caption h4 {
          padding: 4px 5px;
          line-height: 14px; }
  .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video-list {
    height: 100%; }
  .vjs-responsive .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video,
  .vjs-span-one-col .video-js .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video {
    width: calc(50% - 10px);
    height: calc(50% - 10px); }
    .vjs-responsive .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video:nth-child(n+5),
    .vjs-span-one-col .video-js .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video:nth-child(n+5) {
      display: none; }
  .vjs-span-two-col .video-js .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video,
  .vjs-span-three-col .video-js .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video {
    width: calc(25% - 10px);
    height: calc(25% - 10px); }
    .vjs-span-two-col .video-js .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video:nth-child(1),
    .vjs-span-three-col .video-js .mol-related-videos-view-ended .mol-related-videos-panel-ended .mol-related-videos-video:nth-child(1) {
      width: calc(50% - 10px);
      height: calc(50% - 10px - 1px); }

.vjs-playlist-container {
  position: absolute;
  right: 0;
  top: 50%;
  overflow: hidden;
  background-color: black;
  color: white;
  padding: 0 10px !important;
  height: 30%;
  width: 27%;
  max-height: 182px;
  max-width: 260px;
  cursor: pointer; }

.vjs-playlist-wrapper {
  width: 100%;
  height: 70%;
  overflow: hidden; }

.vjs-playlist-wrapper:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.vjs-playlist-wrapper > img {
  vertical-align: middle;
  height: auto;
  width: 100%; }

.vjs-playlist-close-button {
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 24px;
  font-weight: bold; }

.vjs-playlist-container h3 {
  text-transform: uppercase;
  margin: 0;
  padding: 9px 0 3px 0;
  font-size: 16px;
  font-weight: bold; }

.vjs-playlist-caption {
  padding: 4px 10px 4px 10px !important;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  height: 36px;
  position: absolute;
  bottom: 0;
  left: 0; }

.vjs-playlist-caption h4 {
  font-weight: normal;
  margin: 0;
  font-size: 14px; }

.vjs-paused .vjs-playlist-container {
  display: none !important; }

.vjs-responsive .vjs-playlist-container,
.vjs-fixed.vjs-span-one-col .vjs-playlist-container {
  display: none; }

[data-mol-fe-video-preview].frames-container {
  position: relative;
  box-sizing: border-box; }
  [data-mol-fe-video-preview].frames-container .frame-holders {
    position: relative;
    width: 100%;
    height: 100%; }
    [data-mol-fe-video-preview].frames-container .frame-holders .frame-holder {
      opacity: 0;
      transition-property: opacity;
      transition-delay: 0s;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: 0 0;
      background-repeat: repeat;
      will-change: background-position;
      z-index: 0;
      filter: none; }
    [data-mol-fe-video-preview].frames-container .frame-holders .frame-holder.frame-holder-current {
      transition: none;
      opacity: 1;
      z-index: 1; }
    [data-mol-fe-video-preview].frames-container .frame-holders .frame-holder.frame-holder-next {
      opacity: 1;
      z-index: 2; }

.video-js [data-mol-fe-video-preview] {
  z-index: 2 !important;
  background: #000; }

.video-js .vjs-big-play-button {
  z-index: 3; }

.video-js.mol-fe-animated-preview.vjs-fullscreen .vjs-big-play-button {
  left: 50%;
  margin-left: -100px;
  transform: translateX(0); }

.video-js.mol-fe-animated-preview .vjs-big-play-button {
  width: 300px; }
  .video-js.mol-fe-animated-preview .vjs-big-play-button::before {
    width: 100px; }
  .video-js.mol-fe-animated-preview .vjs-big-play-button::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    color: #ffffff;
    font-size: 18px;
    line-height: 100px;
    font-weight: bold;
    text-align: left;
    content: 'Watch the full video'; }

.video-js.mol-fe-animated-preview.ima-ad-playing video[data-mol-fe-video-preview], .video-js.mol-fe-animated-preview.vjs-ad-playing video[data-mol-fe-video-preview], .video-js.mol-fe-animated-preview.vjs-has-started video[data-mol-fe-video-preview] {
  display: none; }

.video-js.mol-fe-animated-preview:not(.vjs-has-started) .vjs-volume-menu-button {
  pointer-events: none; }
  .video-js.mol-fe-animated-preview:not(.vjs-has-started) .vjs-volume-menu-button::before {
    color: #363636; }

.vjs-responsive .video-js.mol-fe-animated-preview.vjs-fullscreen .vjs-big-play-button,
.vjs-span-one-col .video-js.mol-fe-animated-preview.vjs-fullscreen .vjs-big-play-button {
  margin-left: -65px; }

.vjs-responsive .video-js.mol-fe-animated-preview .vjs-big-play-button,
.vjs-span-one-col .video-js.mol-fe-animated-preview .vjs-big-play-button {
  width: 190px; }
  .vjs-responsive .video-js.mol-fe-animated-preview .vjs-big-play-button::before,
  .vjs-span-one-col .video-js.mol-fe-animated-preview .vjs-big-play-button::before {
    width: 60px; }
  .vjs-responsive .video-js.mol-fe-animated-preview .vjs-big-play-button::after,
  .vjs-span-one-col .video-js.mol-fe-animated-preview .vjs-big-play-button::after {
    width: 130px;
    font-size: 16px;
    line-height: 60px;
    content: 'Watch full video'; }

.mol-branded-content .vjs-progress-control {
  pointer-events: none; }

.mol-branded-content.vjs-user-active .vjs-progress-control {
  font-size: 3px; }

.mol-branded-content .vjs-social-controls {
  display: none; }

.mol-branded-content .vjs-play-control.vjs-control {
  min-width: 40px !important; }
  .mol-branded-content .vjs-play-control.vjs-control::after {
    display: none; }

.mol-branded-content .mol-branded-content__sponsored-label.vjs-control {
  width: 102px;
  height: 40px;
  font-size: 12px;
  line-height: 40px;
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  float: left; }

.mol-branded-content .mol-branded-content__blocker {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICR‌​AEAOw=="); }

.mol-branded-content .mol-branded-content__end-view {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .mol-branded-content .mol-branded-content__end-view .mol-branded-content__end-view-blocker {
    display: block;
    position: relative;
    width: 100%;
    height: 100%; }
  .mol-branded-content .mol-branded-content__end-view .mol-branded-content__end-view-poster {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%); }

.vjs-video-container.vjs-fixed.vjs-span-one-col .mol-branded-content__end-view {
  top: 44px;
  bottom: 40px;
  height: calc(100% - 84px); }

.vjs-video-container.vjs-fixed.vjs-span-one-col .vjs-fullscreen .mol-branded-content__end-view {
  height: calc(100% - 86px);
  top: 36px;
  bottom: 50px; }

.vjs-video-container.vjs-responsive .mol-branded-content__end-view-blocker {
  position: relative;
  height: 100% !important; }

.vjs-video-container.vjs-responsive.branded-content__player .vjs-volume-menu-button {
  display: block !important; }

.branded-content__player video {
  visibility: hidden; }

.branded-content__player .mol-branded-content video {
  visibility: visible; }

.branded-content__player .mol-editorial-content video {
  visibility: visible; }

.vjs-tap-to-unmute-control {
  display: block;
  position: absolute;
  top: 36px;
  left: 5px;
  width: 160px;
  cursor: pointer;
  color: #000;
  background-color: #fff;
  text-align: center; }
  .vjs-tap-to-unmute-control:before {
    content: "d";
    font-family: MolVideo;
    font-size: 20px;
    line-height: 40px;
    opacity: 0.3; }
  .vjs-tap-to-unmute-control:after {
    content: "Tap to unmute";
    font-size: 16px;
    line-height: 40px;
    vertical-align: top;
    padding-left: 10px; }
  .vjs-responsive .vjs-tap-to-unmute-control,
  .vjs-span-one-col .video-js .vjs-tap-to-unmute-control {
    top: 44px; }

.video-js.mol-embed-dmtv-video .vjs-title,
.video-js.mol-embed-dmtv-video .vjs-control-bar {
  background: #001E47; }

body.mol-hide-facebook .vjs-social-share-control-facebook-button {
  display: none !important; }

body.mol-hide-facebook .mol-related-videos-panel-social .vjs-social-share-control-facebook-button,
body.mol-hide-facebook .mol-related-videos-panel-social .vjs-facebook-button-icon {
  display: none !important; }

body.mol-hide-facebook .mol-related-videos-panel-social .vjs-social-popup .vjs-social-share-control-facebook-button {
  display: none !important; }

body.mol-hide-facebook .vjs-span-two-col .mol-related-videos-panel-social .vjs-social-share-control-twitter-button,
body.mol-hide-facebook .vjs-span-two-col .mol-related-videos-panel-social .vjs-social-share-control-mail-button {
  margin-right: 10px !important; }

body.mol-hide-facebook .vjs-span-two-col .mol-related-videos-panel-social .vjs-social-share-control-pinterest-button {
  margin-right: 0 !important; }

body.mol-hide-facebook .vjs-span-two-col .mol-related-videos-panel-social .vjs-social-share-control-share-button {
  width: 75px !important; }
  body.mol-hide-facebook .vjs-span-two-col .mol-related-videos-panel-social .vjs-social-share-control-share-button::before {
    display: none !important; }

body.mol-hide-facebook .vjs-span-three-col .mol-related-videos-panel-social .vjs-social-share-control-twitter-button,
body.mol-hide-facebook .vjs-span-three-col .mol-related-videos-panel-social .vjs-social-share-control-mail-button {
  margin-right: 10px !important; }

body.mol-hide-facebook .vjs-span-three-col .mol-related-videos-panel-social .vjs-social-share-control-pinterest-button {
  margin-right: 0 !important; }

body.mol-hide-facebook .vjs-span-three-col .mol-related-videos-panel-social .vjs-social-share-control-share-button {
  width: 100px !important; }
  body.mol-hide-facebook .vjs-span-three-col .mol-related-videos-panel-social .vjs-social-share-control-share-button::before {
    display: none !important; }

.video-js div {
  min-height: 0px; }
